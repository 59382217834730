import axios from "axios";

export default {
    /**
     * Get last 50 note spese for the logged user
     */
    async getSpese(dipendenteID: string) {
        const data = new FormData();
        data.append("limit", "50");
        data.append("where[nota_spese_dipendente]", dipendenteID.toString());
        data.append("orderby", "nota_spese_creation_date");
        data.append("orderdir", "desc");

        const response = await axios.post("rest/v1/search/nota_spese", data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response.data.data;
    },

    /**
     *
     * @returns Categorie spese array
     */
    async getCategorieSpese() {
        const data = new FormData();
        data.append("orderby", "nota_spese_categoria_value");
        data.append("orderdir", "asc");

        const response = await axios.post("rest/v1/search/nota_spese_categoria", data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        console.log(response);
        return response;
    },

    async saveSpesa(spesa) {
        const response = await axios.post(`rest/v1/create/nota_spese`, spesa, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        console.log(response);
        return response;
    },
};
